




























































































































































































































































import TabsInfoMixin from '@/pages/mapView/components/MapTabs/devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import MapView from '@/views/MapView.vue';
import API, { Types } from '@/modules/API';
import { Reports, Global } from '@/store';
import Utils from '@/modules/Utils';
import DimmingDialog from '@/components/DimmingDialog.vue';
import LoadingDialog from '@/components/LoadingDialog.vue';
import CabinetScheduleDialog from '@/components/CabinetScheduleDialog.vue';
import { single_unit_alert_types, single_unit_probability_alert_types } from '@/store/modules/Global';

@Component({
  components: {
    MapView,
    DimmingDialog,
    LoadingDialog,
    CabinetScheduleDialog
  }
})
export default class FullViewTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  // @ts-ignore
  @Prop() device;
  @Prop() deviceStreams;
  @Prop() cabinetDevices;
  @Prop() phasesLiveData;
  @Prop() closeBtn;

  @Global.State('readonly_user') readonly_user;
  @Global.State('lang') lang;
  @Global.State('timezone') projectTimezone;
  @Reports.State('reportsList') devices;
  @Reports.Getter('commissionedFixtures') commissionedFixtures;
  @Reports.Getter('commissionedCabinets') commissionedCabinets;

  loading = true;
  phases_live_data = {};
  events_history = [];
  Utils = Utils; // for getting access from template
  cabinetData: any = {};
  filteredDevices: string[] = [];
  filteredDevicesAmount: number = 0;
  filterType: string = '';
  secondaryFilter: string[] = [];
  phaseActive: string[] = [];
  phaseActiveAlerts: [];
  circuitActive: string[] = [];
  circuitActiveAlerts: [];
  totalAmount: number = 0;
  filterAlert: any[] = [];
  allDevicesFilter: any = {};
  alertDevicesFilter: any = {};
  unassignedDevices: any[] = [];
  otherDevices: any[] = [];
  showDevicesType: string = 'cabinet';
  unassignedPick: boolean = false;
  selectDevices: boolean = false;
  dimmingDialog: boolean = false;
  scheduleDialog: boolean = false;
  dimming_converting_table = null;
  converted: boolean = false;
  percentToDaliTable: any = null;
  controlled_devices: any[] = [];
  loadingDialog: boolean = false;
  circuitTransform: any = null;
  circuitTransformSplited: any = null;
  circuitsPagination: number = 0;
  filter_menu = false;
  
  async mounted() {
    this.unassignedDevices = this.commissionedFixtures
      .filter((device) => !device['meta.device'].hasOwnProperty('cabinet_id') || device['meta.device'].cabinet_id === '')
      .map((device) => device.id);

    if (this.cabinetDevices.length > 0){
      const devicesAndCabinets = [...this.commissionedFixtures, ...this.commissionedCabinets];
      const current_cabinet_id = this.cabinetDevices[0]['meta.device'].cabinet_id;
      
      this.otherDevices = devicesAndCabinets
        .filter(
          (device) =>
            device['meta.device'].hasOwnProperty('cabinet_id') &&
            device['meta.device'].cabinet_id !== '' &&
            device['meta.device'].cabinet_id !== current_cabinet_id
        ).map((device) => device.id);
    }

    // await this.setCabinetEvents();
    this.filterType = 'all';
    this.getCabinetAlertsData();
    this.$store.commit('Global/setPageTitle', 'Cabinet Details');
    this.setControlledDevices();
    if (this.controlled_devices.length) {
      [this.converted, this.percentToDaliTable] =
        await Utils.convertDimmerValues(this.controlled_devices[0].id);
    }
    this.loading = false;
  }

  isVirtualDevice(meta_device){
    return meta_device.fixture_icon ? meta_device.fixture_icon.toString().toLowerCase() === 'virtual' : false;
  }

  get devicesOnCount(){
    return this.cabinetDevices.filter((device) => !device.power && !this.isVirtualDevice(device['meta.device'])).length;
  }

  get devicesOffCount(){
    return this.cabinetDevices.filter((device) => device.power && !this.isVirtualDevice(device['meta.device'])).length;
  }

  @Watch('cabinetDevices')
  setControlledDevices() {
    this.controlled_devices = this.cabinetDevices.filter((device) => device.sys___active && device.sys___connected && !this.isVirtualDevice(device['meta.device']));
  }

  async setPowerLevel(power_percent) {
    this.loadingDialog = true;
    const dali_power = this.converted
      ? this.percentToDaliTable[power_percent]
      : Math.round((power_percent * 254) / 100);
    const slices = Math.ceil(this.controlled_devices.length / 5);
    let startIndex = 0,
      endIndex = Math.min(this.controlled_devices.length, 5);
    for (let i = 0; i < slices; i++) {
      const current = this.controlled_devices.slice(startIndex, endIndex);
      await Promise.all(
        current.map((device) => {
          try {
            this.updateDevicePower(device.id, dali_power);
          } catch (e) {
            try {
              this.updateDevicePower(device.id, dali_power);
            } catch (e) {}
          }
        })
      );
      startIndex = endIndex;
      endIndex = Math.min(this.controlled_devices.length, endIndex + 5);
    }
    setTimeout(() => {
      this.loadingDialog = false;
    }, 5000);
  }

  updateDevicePower(device_id, power) {
    return API.post(
      '',
      `${Types.PROJECTS}/${this.project.id}/${Types.DEVICES}/${device_id}/streams/power/cmd/`,
      { content: power },
      {},
      'v4'
    );
  }
  getCabinetAlertsData() {
    const cabinetMeta = this.$props.device['meta.device'];
    this.totalAmount = this.$props.cabinetDevices.length;
    let project_alerts = this.$store.state.Projects.alerts[this.project.id];
    project_alerts = project_alerts && project_alerts.cabinets && project_alerts.cabinets[cabinetMeta.cabinet_id] && project_alerts.cabinets[cabinetMeta.cabinet_id].list || [];

    const all_alerts_types = single_unit_alert_types.concat(single_unit_probability_alert_types);
    const cabinet = {
      ...this.$props.device,
      devices: this.$props.cabinetDevices,
      alerts: project_alerts.filter((alert) => alert.device.length > 0 && all_alerts_types.includes(alert.alert_type))
    };

    const devicesId = this.$props.cabinetDevices.map((device) => device.id);
    const alertDevices = [
      ...new Set(
        cabinet.alerts
          .filter((device) => devicesId.indexOf(device.device) > -1)
          .map((dev) => dev.device)
      )
    ];
    this.filterAlert = alertDevices;

    const tempAllDevicesFilter = {R: [], S: [], T: []};
    const tempCircuits = cabinet.devices.filter((related_device) => related_device['meta.device'].hasOwnProperty('circuit_number'));
    const circuitList = [
      ...new Set(tempCircuits.map((dev) => dev['meta.device'].circuit_number))
    ]
      .sort((a, b) => (a > b ? 1 : -1))
      .filter((cir) => cir.toString().trim() !== '');
    const transformList = {};
    for (let i = 0; i < circuitList.length; i++) {
      tempAllDevicesFilter[`${i + 1}`] = [];
      transformList[`${i + 1}`] = circuitList[i];
    }
    this.circuitTransform = transformList;
    if (Object.keys(transformList).length > 6) {
      const circuitTransformSplited = Array(Math.ceil(Object.keys(transformList).length / 6));
      let i = 0;
      for (let index = 0; index < circuitTransformSplited.length; index++) {
        circuitTransformSplited[index] = {};
      }
      for (const iterator of Object.entries(transformList)) {
        circuitTransformSplited[i][`${iterator[0]}`] = iterator[1];
        if (Object.keys(circuitTransformSplited[i]).length === 6) {
          i++;
        }
      }
      this.circuitTransformSplited = circuitTransformSplited;
      this.circuitsPagination = 0;
    }
    for (const field in tempAllDevicesFilter) {
      if (['R', 'S', 'T'].indexOf(field) === -1) {
        tempAllDevicesFilter[field] = cabinet.devices
          .filter((device) => device['meta.device'].circuit_number === transformList[field])
          .map((dev) => dev.id);
      } else {
        tempAllDevicesFilter[field] = cabinet.devices
          .filter((device) => device['meta.device'].phase === field)
          .map((dev) => dev.id);
      }
    }

    this.allDevicesFilter = tempAllDevicesFilter;
    this.alertDevicesFilter = {R: [], S: [], T: []};
    for (let i = 0; i < circuitList.length; i++) {
      this.alertDevicesFilter[`${i + 1}`] = [];
    }
    for (const type in this.alertDevicesFilter) {
      this.alertDevicesFilter[type] = this.allDevicesFilter[type].filter(
        (device) => this.filterAlert.indexOf(device) > -1
      );
    }
    this.cabinetData = cabinet;
    this.setMainFilter('all');
    this.setFilteredDevices();
    this.filteredDevices = [...this.cabinetData.devices.map((device) => device.id), this.device.id];
  }

  setMainFilter(type) {
    this.filterType = type;
    this.secondaryFilter = [];
    if (type === 'all') {
      this.filteredDevicesAmount = this.totalAmount;
      this.filteredDevices = [...this.cabinetData.devices.map((device) => device.id), this.device.id];
    } else {
      this.filteredDevicesAmount = this.filterAlert.length;
      this.filteredDevices = [...this.filterAlert, this.device.id];
    }
  }

  setSecondFilterPhase(type) {
    if (this.secondaryFilter.indexOf(type) > -1) {
      this.secondaryFilter.splice(this.secondaryFilter.indexOf(type), 1);
    } else if (
      this.secondaryFilter.length === 0 ||
      this.secondaryFilter.indexOf('1') > -1 ||
      this.secondaryFilter.indexOf('2') > -1 ||
      this.secondaryFilter.indexOf('3') > -1 ||
      this.secondaryFilter.indexOf('4') > -1 ||
      this.secondaryFilter.indexOf('5') > -1 ||
      this.secondaryFilter.indexOf('6') > -1
    ) {
      this.secondaryFilter = [type];
    } else {
      this.secondaryFilter = [...this.secondaryFilter, type];
    }
    this.setFilteredDevices();
  }

  setSecondFilterCircuit(type) {
    if (this.secondaryFilter.indexOf(type) > -1) {
      this.secondaryFilter.splice(this.secondaryFilter.indexOf(type), 1);
    } else if (
      this.secondaryFilter.length === 0 ||
      this.secondaryFilter.indexOf('R') > -1 ||
      this.secondaryFilter.indexOf('S') > -1 ||
      this.secondaryFilter.indexOf('T') > -1
    ) {
      this.secondaryFilter = [type];
    } else {
      this.secondaryFilter = [...this.secondaryFilter, type];
    }
    this.setFilteredDevices();
  }

  // async setCabinetEvents() {
  //   const stream = { id: `${this.device.id}.sys___event` };
  //   this.events_history = await Utils.fetchSingleStreamHistory({
  //     device: this.device,
  //     stream,
  //     period: '1weeks'
  //   });
  //   this.events_history = this.events_history.filter(([timestamp, value]) => value !== 'pong');
  //   this.events_history = this.events_history.map(([timestamp, value]) => {
  //     const index = value.lastIndexOf('#');
  //     const msg = value.slice(index + 1, -1);
  //     return [timestamp, msg];
  //   });
  // }

  circuitBack(){
    this.circuitsPagination = this.circuitsPagination === 0
      ? this.circuitTransformSplited.length - 1
      : this.circuitsPagination - 1;
  }

  circuitNext(){
    this.circuitsPagination = this.circuitsPagination === this.circuitTransformSplited.length - 1
      ? 0
      : this.circuitsPagination + 1;
  }

  setFilteredDevices() {
    if (this.filterType === 'alerts' && this.secondaryFilter.length === 0) {
      this.filteredDevices = [...this.filterAlert, this.device.id];
      this.filteredDevicesAmount = this.filterAlert.length;
    }
    if (this.filterType === 'all' && this.secondaryFilter.length > 0) {
      let tempDevices = [this.device.id];
      for (const i of this.secondaryFilter) {
        tempDevices = [...tempDevices, ...this.allDevicesFilter[i]];
      }
      this.filteredDevicesAmount = tempDevices.length - 1;
      this.filteredDevices = tempDevices;
    } else if (this.filterType === 'all') {
      const tempDevices = JSON.parse(JSON.stringify(this.cabinetData.devices));
      this.filteredDevicesAmount = tempDevices.length;
      this.filteredDevices = [
        ...tempDevices.map((device) => device.id),
        this.device.id
      ];
    }
    if (this.filterType === 'alerts' && this.secondaryFilter.length > 0) {
      let tempDevices = [this.device.id];
      for (const i of this.secondaryFilter) {
        tempDevices = [...tempDevices, ...this.alertDevicesFilter[i]];
      }
      this.filteredDevicesAmount = tempDevices.length - 1;
      this.filteredDevices = tempDevices;
    }
  }

  checkBadgeCount(sign, value, key){
    if (sign === '<'){
      return (this.filterType === 'all' && this.allDevicesFilter[key].length < value) 
        || (this.filterType !== 'all' && this.alertDevicesFilter[key].length < value);
    }
    return (this.filterType === 'all' && this.allDevicesFilter[key].length > value) 
      || (this.filterType !== 'all' && this.alertDevicesFilter[key].length > value);
  }

  @Watch('selectDevices')
  closeFilter(newVal) {
    if (!newVal) {
      this.showDevicesType = 'cabinet';
    }
  }
}
